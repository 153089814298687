<template>
  <div class="footer" v-if="hide">
    <img class="app" src="../../image/app.png" alt="">
    <img class="qrcode" src="../../image/kehu.png">
    <img class="content" src="../../image/floatContent.png">
    <img class="close" src="../../image/close.png" alt="" @click="close">
  </div>
</template>

<script>
export default {
  data() {
    return {
      hide: true
    }
  },
  methods: {
    close() {
      this.hide = false
    }
  }
}
</script>

<style scoped>
.footer {
  width: 100%;
  background: white;
  box-shadow: #ccc 0 0 25px -5px ;
/* box-shadow: 0 0 20px -5px green;*/ /*也可以自定义颜色*/

  height: 0;
  border-top: none;
  border-bottom: 158px transparent solid;
  position: fixed;
  bottom: 0px;
  right: 0;
}

.footer_left {
  float: left;
  margin-left: 300px;
  color: #FFFFFF;
  text-align: left;
  font-size: 14px;
  line-height: 23px;

}

.footer_right {
  float: right;
  margin-right: 300px;
  color: #fff;
  text-align: left;
  font-size: 14px;
  line-height: 23px;
}

.close {
  float: right;
  margin-top: 20px;
  margin-right: 30%;
}
.app {
  width: 13.5%;
  margin-top: -100px;
}

.qrcode {
  width: 7%;
  margin-bottom: 13%;
  padding-left: 2%;
}

.content {
  width: 16%;
  margin-bottom: 14%;
  padding-left: 1%;
}
</style>
