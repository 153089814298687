<template>
  <div>
    <div class="t1">法律服务</div>
    <div class="t2">为您提供专业的法律服务</div>

    <div class="classificationInfo">
      <h1>{{this.queryData.name}}</h1>
      <div class="centerD">
        <img :src="this.queryData.img" />
        <div class="centerDRight">
            <div class="rinBox">
              <img src="@/image/t5.png" />
              <div class="rinBoxRight">
                <p class="p3">刘律师</p>
                <p>天津法链律师事务所</p>
              </div>
            </div>
            <div class="telMaster">
              <p>合作电话：</p>
              <p class="hit">18822629090</p>
              <p>商务合作：</p>
              <p class="hit">huifengxinghua@163.com</p>
            </div>

        </div>
      </div>



    </div>



    <div class="serListM">
      <ul>
        <li v-for=" (item,index) in list" :key="index">
          <img src="@/image/t6.png" />
          <div class="conterT">
            <div class="conterTLeft">
              <p class="p1">{{item.name}}</p>
              <p class="p2">价格：{{item.price}}</p>
            </div>
            <div class="conterTRight">
              <!-- <p @click="more">查看详情</p> -->
            </div>
          </div>
        </li>
      </ul>
    </div>
    <Kefu/>
    <div class="Float">
      <Float/>
    </div>
    
    <div class="footer">
      <Footer/>
    </div>
    
  </div>
</template>

<script>
import Footer from '../../components/footer'
import Kefu from '../../components/kefu'
import Float from '../../components/footer/float'


export default {
  name: 'legalService',
  components: {
    Footer,
    Kefu,
    Float
  },
  data() {
    return {
      show: false,
      attorney: false,
      yc: false,
      ym: false,
      falg:0,
      list:[
        {
          name:"王律师",
          price:'50元/小时起'
        },
        {
          name:"毕律师",
          price:'50元/小时起'
        },
        {
          name:"刘律师",
          price:'50元/小时起'
        },
        {
          name:"丁律师",
          price:'50元/小时起'
        },
        {
          name:"郭律师",
          price:'50元/小时起'
        }
      ],
      queryData:null
    }
  },
  mounted(){
    this.queryData = this.$route.query
  },
  methods: {
    more(){
      this.$router.push('/info')
    }
  },
}

</script>

<style >

/*包含以下四种的链接*/
a {
  text-decoration: none;
  color: #fff
}

/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}

/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}

/* 正在点击的链接*/
a:active {
  text-decoration: none;
}
.pBody{
  width: 1200px;
  margin: 30px auto 0;
  height: auto;
  overflow: hidden;
  padding-bottom: 25px;
  border-bottom: #f5f5f6 solid 1px
}
.t1, .t2{
  width: 100%;
  height: auto;
  background: #f5f5f6;
}
.t1{
  font-size: 34px;
  padding-top: 30px;
  margin-top: 60px;
}
.t2{
  font-size: 22px;
  padding-bottom: 30px;
  padding-top: 15px;
}
.footer{
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.Float{
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
}
.classificationInfo{
  width: 1200px;
  height: auto;
  margin: 20px auto 80px;
  overflow: hidden;
  text-align: left;
}
.centerD{
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 20px;
}
.centerD img{
  width: 780px;
  height: 400px;
  float: left;
}
.centerDRight{
  width: calc(100% - 800px);
  height: 400px;
  float: right;
  border: 1px solid #e4e6f0;
  padding: 10px 24px 30px 24px;
  box-sizing: border-box;
}
.rinBox{
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 20px 0 20px;
  border-bottom: 1px solid #e4e6f0;
}
.rinBox img{
  width: 80px;
  height: 80px;
  float: left;
}
.rinBoxRight{
  width: calc(100% - 90px);
  float: right;
}
.rinBoxRight p{
   margin-top: 10px;
   text-indent: 0
}
.rinBoxRight p.p3{
  font-weight: bold
}
.telMaster{
  width: 100%;
  margin-top: 20px
}
.telMaster p{
  margin-top: 10px;
   text-indent: 0;
}
.telMaster p.hit{
  color: #787878
}

.serListM{
  width: 1200px;
  margin: 30px auto 175px;
  height: auto;
  overflow: hidden;
  padding-bottom: 25px;
}
.serListM ul{
  padding: 0;
  margin: 0;
}
.serListM ul li{
  width: 100%;
  overflow: hidden;
  height: auto;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: #f5f5f6 solid 1px
}
.serListM ul li img{
  width: 150px;
  overflow: hidden;
  height: 180px;
  float: left
}
.serListM ul li .conterT{
  width: calc(100% - 180px);
  overflow: hidden;
  height: 150px;
  float: right;
  text-align: left;
}
.serListM ul li .conterT p{
  text-indent: 0
}
.conterTLeft{
  width: 50%;
  overflow: hidden;
  height: 150px;
  float: left;
  text-align: left;
  margin-top: 30px;
}
.conterTLeft .p1{
  font-size: 24px;
}
.conterTLeft .p2{
  font-size: 30px;
  margin-top: 30px;
  color: red
}
.conterTRight{
  width: 50%;
  overflow: hidden;
  height: 150px;
  float: right;
  text-align: right;
}
.conterTRight P{
  color:#787878;
  cursor: pointer;
}



</style>
