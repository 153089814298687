<template>
  <div class="footer">
      <div class="footer_left">
          <div><img src="../../image/footer.png" alt=""></div>
          <div>主办单位：天津律助科技有限公司<br> 技术支持：天津证公法服科技有限公司 </div>
      </div>
      <div class="footer_right">
          商务合作：huifengxinghua@163.com <br>
          合作电话：18822629090<br> 
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010302001878" style="font-size: 14px; color: rgb(255, 255, 255);">
          津公网安备 12010302001878号</a><br>
          <a target="_blank" href="https://beian.miit.gov.cn/" style="font-size: 14px; color: rgb(255, 255, 255);">备案：津ICP备20002302号-1</a>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer{
    width: 100%;
    height: 118px;
    background:#262A33;
    padding-top: 40px;
}
.footer_left{
    float: left;
    margin-left: 300px;
    color:#FFFFFF;
    text-align: left;
     font-size: 14px;
    line-height: 23px;

}
.footer_right{
    float: right;
    margin-right: 300px;
    color:#fff;
    text-align: left;
    font-size: 14px;
    line-height: 23px;


}
</style>