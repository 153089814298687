<template>
  <div>
    <div style="margin-top:60px">
      <img class="images1" src="../../image/content1.png" alt=""/>
      <div>
        <img class="download-banner" src="../../image/download.png" alt="">
        <div>
          <!-- <button class="download-btn1" :class="{ 'color': show== true }" @click="client">用户端下载</button> -->
        </div>
        <div>
          <button class="download-btn2" :class="{ 'color': show== true }" @click="lawyer">律师端下载</button>
        </div>
      </div>
<!--      <div class="download">-->
<!--        <a name="toc0">-->
<!--          <div class="download_client">-->
<!--            <div class="client_left">-->
<!--              <img class="left_image" src="../../image/kehu.png" alt="">-->
<!--              <div class="left_txt">律助-客户端</div>-->
<!--              <button class="left_buttoms" :class="{ 'color': show== true }" @click="client">立即下载</button>-->
<!--            </div>-->
<!--            <div class="client_left">-->
<!--              <img class="left_image" src="../../image/lvshi.png" alt="">-->
<!--              <div class="left_txt">律助-律师端</div>-->
<!--              <button class="left_buttoms" :class="{ 'colors': attorney  == true }" @click="lawyer">立即下载</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
      <img class="images2" src="../../image/content2.png" alt="">
      <div class="bgack">
        <div class="mentony">
          <button class="dl">注册/登录</button>
          <a href="#toc0">
            <button class="xz">立即下载</button>
          </a>
        </div>
      </div>
    </div>
    <Footer/>
    <Kefu/>
    <Float/>
  </div>
  <!-- 客户：http://machine-zgsfff.oss-cn-zhangjiakou.aliyuncs.com/appDownload/%E5%AE%A2%E6%88%B7.apk -->
  <!--律师: https://legaladvice.zgsfff.com/lawyerApkDownload/ -->
</template>

<script>
import Footer from '../../components/footer'
import Kefu from '../../components/kefu'
import Float from '../../components/footer/float'


export default {
  name: 'app',
  components: {
    Footer,
    Kefu,
    Float
  },
  data() {
    return {
      show: false,
      attorney: false,
      yc: false,
      ym: false

    }
  },
  methods: {
    lawyer() {
      this.attorney = true
      window.open("https://legaladvice.zgsfff.com/lawyerApkDownload/");
    },
    client() {
      this.show = true
      window.open("http://machine-zgsfff.oss-cn-zhangjiakou.aliyuncs.com/appDownload/%E5%AE%A2%E6%88%B7.apk");
    },
  },
}

</script>

<style>
.bgack {
  width: 100%;
  /* height: 6700px; */
  position: relative;
  /* background-image: url('../../image/content.png'); */
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top left;
}

.bgCent {
  width: 100%;
  margin: 0 auto;
  height: 1178px;
}

.bgenmyu {
  width: 200px;
  height: 200px;
  background: #fff;
  position: absolute;
  top: 11.3%;
  left: 38.8%;
}

.bgenm {
  width: 200px;
  height: 200px;
}

.bgenmyuem {
  width: 200px;
  height: 200px;
  background: #fff;
  position: absolute;
  top: 11.3%;
  left: 55.5%;
}

.img1, .img2 {
  width: 44px;
  height: 44px;
}

.img1 {
  margin-top: 40%;
  float: left;
  margin-left: 47%;
}

.img2 {
  margin-top: 40%;
  float: right;

  margin-right: 34%;
}

.left_buttom {
  width: 150px;
  height: 58px;
  background: #5A5A5A;
  opacity: 1;
  border-radius: 8px;
  color: #fff;
  border: 0px;
  float: left;
  margin-top: 13%;
  margin-left: 39.5%;
  font-size: 18px;

}

.right_buttom {
  width: 150px;
  height: 58px;
  background: #5A5A5A;
  opacity: 1;
  border-radius: 8px;
  color: #fff;
  border: 0px;
  float: left;
  margin-top: 13%;
  margin-left: 9.5%;
  font-size: 18px;
}

.scan {
  width: 194px;
  height: 220px;
  position: fixed;
  top: 300px;
  right: 20px;
  background: #fff;
  border: 1px solid #FF791E;
  border-radius: 10px;
  z-index: 999;
}

.temyu {
  position: absolute;
  top: 800px;
  left: 900px;
}

.scan_image {
  width: 88px;
  height: 90px;
  margin-top: 20px;
}

.scan_emi {
  width: 100%;
  color: #646668;
  font-size: 26px;
  margin-top: 5px
}

.scan_emien {
  width: 100%;
  color: #FF791E;
  font-size: 22px;
  margin-top: 5px

}

.mentony {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 80px;
  font-size: 18px;
  margin-bottom: 80px;
}

.dl {
  width: 150px;
  height: 58px;
  border: 1px solid #5A5A5A;
  opacity: 1;
  border-radius: 8px;
  float: left;
  border: 1px solid #5A5A5A;
  color: #C1C1C1;
  margin-left: 38%;
  background: #FFFFFF;
}

.xz {
  width: 150px;
  height: 58px;
  background: #FF791E;
  opacity: 1;
  border-radius: 8px;
  float: left;
  border: 0px;
  color: #fff;
  margin-left: 3%


}

/*  */
.images1 {
  width: 100%;
}

.download-banner {
  width: 100%;
  margin-top: 6%;
}

.download-btn1 {
  width: 150px;
  height: 58px;
  background: #FF791E;
  opacity: 1;
  border-radius: 8px;
  color: #fff;
  border: 0px;
  font-size: 18px;
  position: relative;
  top: -370px;
  left: -170px;
}

.download-btn2 {
  width: 150px;
  height: 58px;
  background: #FF791E;
  opacity: 1;
  border-radius: 8px;
  color: #fff;
  border: 0px;
  font-size: 18px;
  position: relative;
  top: -350px;
  left: -170px;
}

.images2 {
  width: 100%;
}

.download {
  width: 100%;
  height: 500px;
}

.download_client {
  width: 700px;
  height: 500px;
  margin: 0 auto;
}

.client_left {
  width: 50%;
  float: left;
  margin-top: 70px;
}

.left_image {
  width: 176px;
  height: 176px;
}

.left_txt {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 26px;
  margin-top: 20px;

}

.left_buttoms {
  width: 150px;
  height: 58px;
  background: #5A5A5A;
  opacity: 1;
  border-radius: 8px;
  color: #fff;
  border: 0px;
  font-size: 18px;
  margin-top: 40px;


}

.color {
  background-color: #FF791E;
}

.colors {
  background: #FF791E;
}

/*包含以下四种的链接*/
a {
  text-decoration: none;
  color: #fff
}

/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}

/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}

/* 正在点击的链接*/
a:active {
  text-decoration: none;
}

</style>
