import Vue from 'vue'
import Router from 'vue-router'
import home from '../views/home'
import privacy from '../views/privacy'
import legalService from '../views/legalService'
import info from '../views/legalService/info'

Vue.use(Router)

export default new Router({
  // mode: 'history',
//   base: process.env.BASE_URL,
  routes: [
    {
        path: '/',
        name: 'home',
        component: home
    },
    {
        path: '/home',
        name: 'home',
        component: home
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: privacy 
    },
    {
      path: '/legalService',
      name: 'legalService',
      component: legalService 
    },
    {
      path: '/info',
      name: 'info',
      component: info 
    }
  ]
})