<template>
  <div>
    <div class="t1">法律服务</div>
    <div class="t2">为您提供专业的法律服务</div>
    <div class="pBody">
      <div class="classification">
        <div class="classificationLeft" :class="[t == 0 ? 'onTb' : '']" @click="qh(0)">
          法律咨询
        </div>
        <div class="classificationLeft" :class="[t == 1 ? 'onTb' : '']" @click="qh(1)">
          代写函状
        </div>
        <div class="classificationLeft" :class="[t == 2 ? 'onTb' : '']" @click="qh(2)">
          案件办理
        </div>
        <div class="classificationLeft" :class="[t == 3 ? 'onTb' : '']" @click="qh(3)">
          法律顾问
        </div>
      </div>
    </div>
    <div class="serList">
      <ul>
        <li v-for=" (item,index) in list" :key="index">
          <img :src="item.img" />
          <div class="conterT">
            <div class="conterTLeft">
              <p class="p1" @click="more(item)">{{item.name}}</p>
              <p class="p2">价格：{{item.price}}</p>
            </div>
            <div class="conterTRight">
              <p @click="more(item)">查看详情</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <Kefu/>
    <div class="Float">
      <Float/>
    </div>
    
    <div class="footer">
      <Footer/>
    </div>
    
  </div>
</template>

<script>
import Footer from '../../components/footer'
import Kefu from '../../components/kefu'
import Float from '../../components/footer/float'


export default {
  name: 'legalService',
  components: {
    Footer,
    Kefu,
    Float
  },
  data() {
    return {
      show: false,
      attorney: false,
      yc: false,
      ym: false,
      falg:0,
      t:0,
      list:[
        {
          name:"电话咨询",
          price:'50元/小时起',
          img:require("@/image/t1.png")
        },
        {
          name:"面对面咨询",
          price:'200元/小时起',
          img:require("@/image/t1.png")
        }
      ]
    }
  },
  methods: {
    more(item){
      this.$router.push({
        path:'/info',
        query:item
      })
    },
    qh(num){
      switch (num) {
        case 0:
          this.t = num
          this.list = [
            {
              name:"电话咨询",
              price:'50元/小时起',
              img:require("@/image/t1.png")
            },
            {
              name:"面对面咨询",
              price:'200元/小时起',
              img:require("@/image/t1.png")
            }
          ]
          break;
        case 1:
          this.t = num
          this.list = [
            {
              name:"代写起诉状",
              price:'200元起',
              img:require("@/image/t2.png")
            },
            {
              name:"代写控告状",
              price:'200元起',
              img:require("@/image/t2.png")
            },
            {
              name:"代出律师函",
              price:'按照标的收费，500元起',
              img:require("@/image/t2.png")
            },
            {
              name:"代拟定合同",
              price:'500元起',
              img:require("@/image/t2.png")
            },
            {
              name:"代写离婚协议",
              price:'300元起',
              img:require("@/image/t2.png")
            }
          ]
          break;
        case 2:
          this.t = num
          this.list = [
            {
              name:"民事案件",
              price:'按规定比例收费',
              img:require("@/image/t3.png")
            },
            {
              name:"刑事案件",
              price:'按规定比例收费',
              img:require("@/image/t3.png")
            },
            {
              name:"行政案件",
              price:'按规定比例收费',
              img:require("@/image/t3.png")
            },
            {
              name:"国家赔偿案件",
              price:'按规定比例收费',
              img:require("@/image/t3.png")
            }
          ]
          break;
        case 3:
          this.t = num
          this.list = [
            {
              name:"担任法链顾问",
              price:'按年度双方协商收费',
              img:require("@/image/t4.png")
            }
          ]
          break;
        default:
          break;
      }
    }
  },
}

</script>

<style >

/*包含以下四种的链接*/
a {
  text-decoration: none;
  color: #fff
}

/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}

/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}

/* 正在点击的链接*/
a:active {
  text-decoration: none;
}
.pBody{
  width: 1200px;
  margin: 30px auto 0;
  height: auto;
  overflow: hidden;
  padding-bottom: 25px;
  border-bottom: #f5f5f6 solid 1px
}
.t1, .t2{
  width: 100%;
  height: auto;
  background: #f5f5f6;
}
.t1{
  font-size: 34px;
  padding-top: 30px;
  margin-top: 60px;
}
.t2{
  font-size: 22px;
  padding-bottom: 30px;
  padding-top: 15px;
}
.footer{
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.Float{
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
}
.classification{
  width: 100%;
  height: auto;
  margin-top: 20px;
  overflow: hidden;
}
.classificationLeft{
  width: 100px;
  float: left;
  color:#787878;
  cursor: pointer;
}
.onTb{
  color:rgb(255, 121, 30);
}
.classification ul{
  width: calc(100% - 100px);
  float: right;
  overflow: hidden;
}
.classification ul li{
  width: auto;
  float: left;
  padding: 2px 10px;
  font-size: 14px;
  margin-right: 15px;
  color:#787878;
  cursor: pointer;
}
.classification ul li span{
  margin-left: 5px;
}
.classification ul li:hover{
  color:rgb(255, 121, 30);
  transform:scale(1.1);
  transition: all .1s
}
.serList{
  width: 1200px;
  margin: 30px auto 175px;
  height: auto;
  overflow: hidden;
  padding-bottom: 25px;
}
.serList ul{
  padding: 0;
  margin: 0;
}
.serList ul li{
  width: 100%;
  overflow: hidden;
  height: 150px;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: #f5f5f6 solid 1px
}
.serList ul li img{
  width: 200px;
  overflow: hidden;
  height: 150px;
  float: left
}
.serList ul li .conterT{
  width: calc(100% - 220px);
  overflow: hidden;
  height: 150px;
  float: right;
  text-align: left;
}
.serList ul li .conterT p{
  text-indent: 0
}
.conterTLeft{
  width: 50%;
  overflow: hidden;
  height: 150px;
  float: left;
  text-align: left;
}
.conterTLeft .p1{
  font-size: 24px;
  cursor: pointer;
}
.conterTLeft .p2{
  font-size: 30px;
  margin-top: 30px;
  color: red
}
.conterTRight{
  width: 50%;
  overflow: hidden;
  height: 150px;
  float: right;
  text-align: right;
}
.conterTRight P{
  color:#787878;
  cursor: pointer;
}



</style>
