<template>
  <div class="header">
    <div><img class="header_log" src="../../image/login.png" alt=""></div>
    <div class="header_meny">
      <ul class="header_ul">
        <li class="header_li" :style="{'color': falg == 0 ? '#FF791E' : ''}">
          <div  @click="toHome">关于律助</div>
          <div v-if="falg == 0" class="lienm"></div>
        </li>
        <li class="header_li" @mousemove="moveFn" @mouseout="moveoutFn">
          <div>下载</div>
          <!-- QRCODE -->
          <div class="qrcode" :style="{display: display}" @mousemove="moveFn" @mouseout="moveoutFn">
            <div class="qrcode-box" style="margin-bottom:10px;margin-top:10px;">
              <div style="text-align: center;">
                <img src="../../image/kehu.png" width="90%">
                <p style="text-indent: 0px">扫描下载APP</p>
              </div>
            </div>
          </div>
        </li>
        <li class="header_li" :style="{'color': falg == 2 ? '#FF791E' : ''}" @click="legalService">
          法律服务
          <div v-if="falg == 2" class="lienm"></div>
        </li>
        <li class="header_li" :style="{'color': falg == 1 ? '#FF791E' : ''}" @click="toPrivacy">
          隐私政策
          <div v-if="falg == 1" class="lienm"></div>
        </li>
      </ul>
    </div>
    <div class="header_right">
      <div class="rightem"><img class="right_img" src="../../image/phone.png" alt=""></div>
      <div class="panment">18822629090</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      display: 'none',
      falg:0
    }
  },
  mounted(){
    this.falg = window.localStorage.getItem('tip') ? window.localStorage.getItem('tip') : 0
  },
  methods: {
    moveFn() {
      this.display = 'block'
    },
    moveoutFn() {
      this.display = 'none'
    },
    toPrivacy(){
      this.$router.push('/privacy')
      this.falg = 1
      window.localStorage.setItem('tip',this.falg)
    },
    toHome(){
      this.$router.push('/home')
      this.falg = 0
      window.localStorage.setItem('tip',this.falg)
    },
    legalService(){
      this.$router.push('/legalService')
      this.falg = 2
      window.localStorage.setItem('tip',this.falg)
    }
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: #ccc 0px 0px 4px 0px;
}

.header_log {
  width: 106px;
  height: 50px;
  float: left;
  margin-left: 300px;
}

.header_meny {
  float: left;
  padding-left: 24%;
}

.header_ul {
  margin-top: 20px;
}

.lienm {
  width: 18px;
  height: 4px;
  background: #FF791E;
  margin: 0 auto;
  border-radius: 2px;
}

.header_li {
  float: left;
  width: 100px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  color: #5A5A5A;
  border-right: 1px solid #F3F3F3;
}

.header_right {
  float: right;
  margin-right: 280px;
}

.rights {
  width: 80px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  float: left;
  background: #FF791E;
  color: #fff;
  border-radius: 6px;

}

.right {
  width: 80px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  float: left;
  border: 1px solid #5A5A5A;
  color: #C1C1C1;
  border-radius: 6px;
  margin-right: 10px;
}

.rightem {
  float: left;
  margin-top: 8px;
  margin-right: 5px;

}

.right_img {
  margin-top: 10px;
  width: 22px;
  height: 22px;
}

.panment {
  float: left;
  line-height: 60px;
  font-size: 16px;
  color: #353637;
}

.qrcode {
  width: 100px;
  height: 130px;
  position: relative;
  top: 10px;
  left: -5px;
  background: #fff;
  box-shadow: #ccc 0 0 10px 1px;
  padding: 5px;
  text-align: left;
  border-radius: 5px;
  z-index: 100;
}

.qrcode-box {
  margin: 0 auto 15px;
  font-size: 14px;
  color: #181818;
}

.qrcode:after {
  box-sizing: content-box;
  width: 0px;
  height: 0px;
  position: absolute;
  top: -20px;;
  left: 45px;
  padding: 0;
  border: 10px solid;
  border-color: transparent transparent white transparent;
  display: block;
  content: '';
  z-index: 10;
}
</style>
