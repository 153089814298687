<template>
  <div>
    <div class="pBody">
      <div class="listTsBg">
        <ul class="listTs">
         <li @click="privacy(0)">隐私政策（律多助小程序）
            <div v-if="falg == 0" class="lienm"></div>
         </li>
         <li @click="privacy(1)">隐私政策（律多助律师）
            <div v-if="falg == 1" class="lienm"></div>
         </li>
         <li @click="privacy(2)">用户服务协议（律多助小程序）
            <div v-if="falg == 2" class="lienm"></div>
         </li>
         <li @click="privacy(3)">用户注册协议（律多助律师）
            <div v-if="falg == 3" class="lienm"></div>
         </li>
       </ul>
      </div>
       
       <ul class="listTsBox">
         <li v-if="falg == 0">
         <div class="conter">
              <iframe  src="https://legaladvice.zgsfff.com/miniprogramroot/privacys.html" width="100%" height="8800" frameborder=no border="0" marginwidth="0" marginheight="0"
                scrolling="no" allowtransparency="yes"></iframe>
          </div>
         </li>
         <li v-if="falg == 1">
           <div class="conter">
              <iframe  src="https://legaladvice.zgsfff.com/miniprogramroot/LSyszc.html" width="100%" height="8800" frameborder=no border="0" marginwidth="0" marginheight="0"
                scrolling="no" allowtransparency="yes">
                
                </iframe>
            </div>
         </li>
         <li v-if="falg == 2">
         <div class="conter">
              <iframe  src="https://legaladvice.zgsfff.com/miniprogramroot/membership.html" width="100%" height="8800" frameborder=no border="0" marginwidth="0" marginheight="0"
                scrolling="no" allowtransparency="yes">
                
                </iframe>
            </div>
           
         </li>
         <li v-if="falg == 3">
           <div class="conter">
              <iframe  src="https://legaladvice.zgsfff.com/miniprogramroot/LSfwxy.html" width="100%" height="8800" frameborder=no border="0" marginwidth="0" marginheight="0"
                scrolling="no" allowtransparency="yes">
                
                </iframe>
            </div>
         </li>
       </ul>

    </div>



    <Kefu/>
    <div class="Float">
      <Float/>
    </div>
     <Footer/>
  </div>
</template>

<script>
import Footer from '../../components/footer'
import Kefu from '../../components/kefu'
import Float from '../../components/footer/float'


export default {
  name: 'app',
  components: {
    Footer,
    Kefu,
    Float
  },
  data() {
    return {
      show: false,
      attorney: false,
      yc: false,
      ym: false,
      falg:0,

    }
  },
  methods: {
    privacy(num){
      this.falg = num
    }
  },
}

</script>

<style>

/*包含以下四种的链接*/
a {
  text-decoration: none;
  color: #fff
}

/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}

/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}

/* 正在点击的链接*/
a:active {
  text-decoration: none;
}
.pBody{
  width: 1200px;
  margin: 80px auto 0;
  height: auto;
}
.listTsBg{
  width: 100%;
  position: fixed;
  top: 58px;
  left: 0;
  background-color: #fff;
  padding-top: 20px;
    box-shadow: #ccc 0px 0px 4px 0px;
}
.listTs{
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.listTs li{
  width: 25%;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #8D8D8D;
  float: left;
}
.listTsBox{
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin-top: 128px
}
.listTsBox li{
  width: 1200px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #8D8D8D;
  text-align: left
}
.lienm {
  width: 60px;
  height: 2px;
  background: #FF791E;
  margin: 5px auto;
  border-radius: 2px;
}

.conter p, .conter1 p{
  line-height: 25px
}
.conter{
		padding:5px 15px;
		font-size: 15px;
		background: #fff;
    margin-bottom: 200px
	}
  .conter1{
		padding:5px 15px;
		font-size: 15px;
		background: #fff;
    margin-bottom: 50px;
    height: 600px;
	}
	.bluf{
		font-weight: 700;
	}
	.ttt{
		decoration:underline
	}
	.ter{
		color:#ff0000;
	}
	p{
		text-indent:2em;
	}
  .Float{
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.underline{
		text-decoration:underline;
		font-weight: 700;
	}
	.underlinespan{
		text-decoration:underline;
	}

</style>
