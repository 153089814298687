<template>
  <div id="app">
    <Header/>
    <router-view />
  </div>
</template>

<script>

import Header from './components/header'


export default {
  name: 'app',
  components: {
   
    Header
  }
}
</script>

<style>
*{
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
