<template>
  <div>
    <div class="kefueject" @mousemove="moveFn" @mouseout="moveoutFn">
      <div class="kefu">联<br>系<br>我<br>们</div>
    </div>
    <!--  -->
    <div class="kefuEjectNone" :style="{display: display}" @mousemove="moveFn" @mouseout="moveoutFn">
      <div class="kefuBox" style="margin-bottom:30px;margin-top:10px;">
        <div style="text-align: center;">
          <img src="../../image/login.png" width="90%">
        </div>
      </div>
      <div class="kefuBox">
        <p>合作电话</p>
        <p class="p1">18822629090</p>
      </div>
      <div class="kefuBox">
        <p>商务合作</p>
        <p class="p1">huifengxinghua@163.com</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {},
  data() {
    return {
      display: 'none'
    }
  },
  methods: {
    moveFn() {
      this.display = 'block'
    },
    moveoutFn() {
      this.display = 'none'
    }
  },
}
</script>
<style scoped>
.kefu {
  width: 68px;
  height: 98px;
  position: absolute;
  right: 0;
  top: 120px;
  background: url('../../image/kefu.png') no-repeat;
  text-align: center;
  padding-top: 60px;
  line-height: 18px;
  color: #AAAAAA;
}

.kefueject {
  width: 72px;
  height: 200px;
  position: fixed;
  right: 20px;
  top: 200px;
  text-align: center;
  padding-top: 60px;
  line-height: 18px;
}

.kefuEjectNone {
  width: 200px;
  height: 230px;
  position: fixed;
  top: 245px;
  right: 90px;
  background: #fff;
  box-shadow: #ccc 0 0 10px 1px;
  padding: 10px;
  text-align: left;
  border-radius: 5px;
  z-index: 100;
}

.kefuEjectNone:after {
  box-sizing: content-box;
  width: 0px;
  height: 0px;
  position: absolute;
  top: 180px;;
  right: -18px;
  padding: 0;
  border-right: 9px solid transparent;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 9px solid #fff;
  display: block;
  content: '';
  z-index: 10;
}

.kefuBox {
  width: 90%;
  margin: 0 auto 15px;
  font-size: 14px;
  color: #181818;
}

.kefuBox .p1 {
  color: #999;
}

</style>
